import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Data() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{t("Aton Invest - Personal data processing policy")}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center space-y-big">
          <div className="col-12 col-lg-10">
            <div className="spaced">
              <h1>Politique de traitement des données personnelles</h1>
              <h2>Dispositions générales</h2>
              <p>
                Cette politique de traitement des données personnelles fait
                partie de l’avertissement légal du site www.aton-invest.com
                (ci-après le « Site ») s’applique à tout utilisateur du Site et
                décrit le traitement des données personnelles éventuellement
                collectées par Aton Invest SAS lors de l’accès au Site par
                l’utilisateur ainsi que pendant son utilisation.
              </p>
              <h2>Collecte de Données Personnelles par Aton Invest SAS</h2>
              <p>
                Aton Invest SAS collecte des données directement auprès de vous
                (lorsque vous consultez le site Internet de Aton Invest SAS,
                demandez à être contacté, participez à un sondage ou un
                événement organisé par Aton Invest SAS), mais également des
                données concernant d’autres personnes que vous seriez amené à
                nous fournir parce qu’elles ont un lien avec vous. Lorsque vous
                transmettez les données personnelles de tiers, vous vous engagez
                à informer la personne à qui se rapportent ces données que Aton
                Invest SAS traite ses données personnelles.
              </p>
              <p>
                La navigation sur le Site est possible sans fournir de Données
                Personnelles vous concernant. Toutefois, pour accéder à
                certaines parties du Site, par exemple pour le téléchargement de
                certains documents, ou dans le cadre d’une autre utilisation
                spécifique, Aton Invest SAS peut être amené à vous demander des
                Données Personnelles. La communication de Données Personnelles
                dans ce cadre est volontaire. Si vous ne fournissez pas ces
                Données Personnelles, vous ne pourrez pas bénéficier des
                services correspondants proposés par le Site, tels que la
                communication de newsletters et le téléchargement de documents.
              </p>
              <h2>Données Personnelles traitées par Aton Invest SAS</h2>
              <p>
                Les "données personnelles" comprennent toute information
                permettant d’identifier directement (par exemple prénom, nom) ou
                indirectement (par exemple combinaison de données) une personne
                physique.
              </p>
              <p>
                Les données personnelles que Aton Invest SAS peut être amené à
                traiter peuvent comprendre :
              </p>
              <ul className="list-round">
                <li>
                  des données d’identification, par exemple nom, adresse, numéro
                  de téléphone, adresse électronique, coordonnées
                  professionnelles ;
                </li>
                <li>
                  des informations professionnelles, par exemple emploi et
                  historique professionnel, titre;
                </li>
                <li>
                  des informations transmises par exemple par les cookies et des
                  technologies similaires sur les sites internet et dans des
                  courriels.
                </li>
              </ul>
              <h2>Base légale et finalités des traitements</h2>
              <p>
                Aton Invest SAS collecte et traite des données personnelles avec
                les finalités (ci-après les "Finalités") et selon les bases
                légales énoncées ci-dessous.
              </p>
              <p>
                De manière générale, Aton Invest SAS base ses traitements sur :
                - la poursuite de son intérêt légitime - son obligation de
                respecter des dispositions légales ou réglementaires ; - votre
                consentement.
              </p>
              <p>
                Les finalités pour lesquelles vos Données Personnelles sont
                collectées sont mentionnées ci-après, accompagnées d’une brève
                description de la manière dont ces données pourront être
                traitées dans chaque cas.
              </p>
              <p>Communiquer avec Aton Invest SAS.</p>
              <p>
                Nous donnerons suite à vos commentaires, ainsi qu’aux autres
                demandes que vous pourrez nous soumettre par l’intermédiaire du
                Site, comme vos questions en ligne, demandes commerciales ou
                l’enregistrement de votre participation à un évènement. A cet
                effet, il peut être nécessaire que nous vous rappelions par
                téléphone ou que nous communiquions avec vous par courrier
                électronique
              </p>
              <p>Marketing direct et promotion.</p>
              <p>
                Le marketing direct et/ou les opérations promotionnelles
                effectuées par Aton Invest SAS peuvent impliquer que nous vous
                fournissons des informations spécifiques que vous avez demandées
                en ligne ou des informations plus générales sur des offres de
                Aton Invest SAS. Lorsque nous rassemblons des Données
                Personnelles à des fins de marketing ou à des fins
                promotionnelles, nous vous offrons toutefois des choix
                appropriés en ce qui concerne leur utilisation.
              </p>
              <p>
                Tenue à jour du Site et conduite d’études de satisfactions
                clients.
              </p>
              <p>
                Aton Invest SAS utilisera vos Données Personnelles pour tenir à
                jour et améliorer le Site. Aton Invest SAS utilisera également
                vos Données Personnelles pour conduire des études de
                satisfaction clients.
              </p>
              <p>
                Compilation de statistiques globales concernant l’utilisation du
                Site.
              </p>
              <p>
                Lorsque vous fournissez des Données Personnelles sur le Site,
                vous garantissez avoir reçu les autorisations et consentements
                nécessaires des personnes concernées par ces Données
                Personnelles si elles concernent d’autres personnes que vous et,
                le cas échéant, pour les utilisations décrites dans la présente
                politique.
              </p>
              <h2>Profilage et prise de décision automatisée</h2>
              <p>
                Aton Invest SAS peut évaluer certaines de vos caractéristiques
                sur la base de données personnelles traitées automatiquement
                (profilage) afin, en particulier, de vous proposer des offres
                personnalisées et des conseils ou informations sur nos produits
                et services. En revanche, Aton Invest ne recourt généralement
                pas à la prise de décision automatisée en rapport avec la
                Relation d’affaires et/ou des Personnes concernées. Si nous
                sommes amenés à le faire, nous respecterons les exigences
                légales et réglementaires applicables.
              </p>
              <h2>Partage de vos données personnelles avec des tiers</h2>
              <p>
                Si nécessaire ou utile pour atteindre les Finalités, Aton Invest
                SAS se réserve le droit de divulguer ou de rendre accessibles
                les données personnelles aux destinataires suivants, pour autant
                que cela soit légalement ou autrement autorisé ou requis :
              </p>
              <ul className="list-round">
                <li>
                  administrations publiques/gouvernementales, tribunaux,
                  autorités compétentes (par exemple autorités de surveillance
                  financières) ou acteurs des marchés financiers (par exemple
                  dépositaires tiers ou centraux, courtiers, bourses et
                  registres) ;
                </li>
                <li>
                  Aton Invest SAS ou sous-traitants tiers qui traitent des
                  données personnelles pour le compte de Aton Invest SAS et/ou à
                  qui Aton Invest SAS confie certaines de ses tâches
                  (externalisation) ;
                </li>
                <li>auditeurs ou conseillers juridiques.</li>
              </ul>
              <p>
                Aton Invest SAS a pour règle de demander à ces fournisseurs
                externes de traiter les Données Personnelles conformément aux
                engagements de Aton Invest SAS en matière de traitement des
                données personnelles, de confidentialité et de sécurité.
              </p>
              <h2>Obligations légales de communication de données</h2>
              <p>
                Aton Invest SAS peut communiquer vos Données Personnelles en cas
                de situations d’urgence pour assurer la sécurité des personnes,
                ou protéger le public ou le Site. Aton Invest pourrait également
                communiquer vos données en vertu de la loi, sur une décision de
                justice, mandat, arrêté ou toute autre réglementation dans tous
                pays où Aton Invest SAS est implanté.
              </p>
              <h2>Transfert hors UE</h2>
              <p>
                En cas de transfert de vos données en dehors de l’EEE, ce
                transfert aura lieu sur la base d’une décision rendue par la
                Commission européenne, lorsque celle-ci a reconnu que le pays
                vers lequel vos données seront transférées assure un niveau de
                protection équivalent à celui existant dans l’EEE.
              </p>
              <p>
                En cas de transfert de vos données vers un pays dont le niveau
                de protection de vos données n’a pas été reconnu comme adéquat
                par la Commission européenne, Aton Invest SAS mettra en place la
                garantie suivante pour assurer la protection de vos données
                personnelles : Clauses contractuelles types approuvées par la
                Commission européenne.
              </p>
              <p>
                Dans ce cadre, Aton Invest SAS vérifiera les mesures de
                protection appropriées qui pourraient être appliquées par ses
                prestataires aux données telles que le cryptage ou la
                tokenisation.
              </p>
              <h2>Vos droits</h2>
              <p>
                Vous avez le droit, sous réserve de la législation applicable en
                matière de protection des données :
              </p>
              <ul className="list-round">
                <li>
                  de demander accès aux données personnelles que Aton Invest SAS
                  détient et d’en recevoir copie ;
                </li>
                <li>
                  le cas échéant, de demander une rectification des données
                  personnelles inexactes ;
                </li>
                <li>
                  le cas échéant, de demander l’effacement des données
                  personnelles lorsque leur traitement n’est plus nécessaire
                  pour atteindre les finalités, ou n’est pas ou plus licite pour
                  d’autres raisons, sous réserve toutefois des durées de
                  conservation applicables (cf. « Durée de conservation »
                  ci-dessous) ;
                </li>
                <li>
                  le cas échéant, de demander la limitation du traitement de vos
                  données personnelles ;
                </li>
                <li>
                  le cas échéant, de vous opposer au Traitement des données
                  personnelles, auquel cas nous ne traiterons plus les données
                  personnelles, sauf si nous avons des raisons impérieuses et
                  légitimes de le faire (par exemple constatation, exercice ou
                  défense d’un droit en justice). Aton Invest SAS ne fera
                  toutefois pas usage de vos données personnelles à des fins de
                  marketing direct si vous nous demandez de nous en abstenir ;
                </li>
                <li>
                  si vous avez donné votre consentement au traitement de vos
                  données personnelles, de retirer votre consentement à tout
                  moment ;
                </li>
                <li>
                  de demander la portabilité de vos données à savoir la
                  restitution des données personnelles que vous nous avez
                  fournies ou, lorsque cela est techniquement possible, le
                  transfert de celles-ci à un tiers ;
                </li>
                <li>
                  de déposer une réclamation auprès de notre délégué à la
                  protection des données (cf. section « Contact » ci-dessous)
                  relative au traitement des données personnelles et, en
                  l’absence d’une réponse satisfaisante, de déposer une
                  réclamation relative au traitement de ces dernières auprès de
                  l’autorité de protection des données compétente.
                </li>
              </ul>
              <h2>Sécurité et intégrité des données</h2>
              <p>
                Aton Invest SAS utilise diverses mesures de sécurité techniques
                ou met en place des Chartes adéquates afin de protéger les
                Données Personnelles collectées, utilisées ou transférées contre
                les pertes, utilisations abusives, falsifications ou
                destructions. Toutefois, le réseau Internet étant ouvert à tous
                et non sécurisé, Aton Invest SAS ne peut être tenu pour
                responsable de la sécurité des transmissions des Données
                Personnelles par Internet.
              </p>
              <h2>Durée de conservation</h2>
              <p>
                Aton Invest SAS conserve les données personnelles aussi
                longtemps que nous en avons besoin pour atteindre les Finalités.
                Dans la même optique, Aton Invest SAS supprimera ou rendra
                anonymes les données personnelles (ou prendra des mesures
                équivalentes) dès lors qu’elles ne seront plus nécessaires pour
                atteindre les Finalités, sous réserve toutefois (i) des
                exigences légales ou réglementaires applicables à la
                conservation des Données personnelles pour une durée plus
                longue, ou (ii) pour faire constater, exercer et/ou défendre des
                droits réels ou potentiels dans le cadre d’actions en justice,
                d’enquêtes ou de procédures similaires, y compris des
                conservations à des fins juridiques que nous pourrions imposer
                pour préserver des informations pertinentes.
              </p>
              <h2>Sites Internet extérieurs</h2>
              <p>
                Le Site peut fournir des liens vers des sites Internet
                extérieurs. Aton Invest SAS ne fournit aucune garantie ni ne
                fait aucune déclaration relative à ces sites Internet
                extérieurs. Les propriétaires et opérateurs de ces sites
                Internet extérieurs peuvent collecter, utiliser ou transférer
                des Données Personnelles dans le cadre de termes et conditions
                différents de ceux de Aton Invest SAS. En vous connectant à un
                site Internet extérieur, vous devez vous informer sur les
                mesures de confidentialité et de sécurité de ces sites Internet
                extérieurs.
              </p>
              <h2>CONTACT</h2>
              <p>
                Pour toute question relative à l’utilisation de vos Données
                personnelles, veuillez envoyer un email à{" "}
                <a href="mailto:contact@aton-invest.com">
                  contact@aton-invest.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
