import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logos/logo-white.png";
import facebook from "../../assets/images/socials/facebook.svg";
import linkedin from "../../assets/images/socials/linkedin2.svg";
import youtube from "../../assets/images/socials/youtube.svg";
import instagram from "../../assets/images/socials/instagram.svg";

import frenchFlag from "../../assets/images/logos/french.svg";
import englishFlag from "../../assets/images/logos/english.svg";
import { API_URL, SwalAlert } from "../../index";
import ReactGA from "react-ga";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

const initialState = {
  email: "",
};

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [newsletterForm, setNewsletterForm] = useState(initialState);

  async function handleSubmit(e) {
    e.preventDefault();

    await fetch(`${API_URL}/newsletter.php`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(newsletterForm),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
      },
    })
      .then((response) => {
        if (response.ok) {
          SwalAlert.fire({
            title: t("Thanks"),
            html: t("Your message has been sent"),
            icon: "success",
          });
          if (getCookieConsentValue("consentCookie")) {
            ReactGA.event({
              category: "EMAIL",
              action: "SEND_EMAIL",
              label: "NEWSLETTER_EMAIL",
            });
          }
          e.target.reset();
          setNewsletterForm(initialState);
        } else {
          SwalAlert.fire({
            title: t("Oops"),
            html: t("Your message has not been sent"),
            icon: "error",
          });
        }
      })
      .catch((error) => {
        SwalAlert.fire({
          title: t("Oops"),
          html: t("Your message has not been sent"),
          icon: "error",
        });
      });
  }

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  return (
    <>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t("Agree")}
        declineButtonText={t("Decline")}
        cookieName="consentCookie"
        buttonStyle={{
          color: "#ffffff",
          fontWeight: "600",
          fontSize: "15px",
          background: "var(--color-gradient-2)",
        }}
        declineButtonStyle={{
          color: "red",
          fontWeight: "600",
          fontSize: "15px",
          background: "transparent",
        }}
        onAccept={() => {
          ReactGA.event({
            category: "COOKIE",
            action: "CONSENT_COOKIE",
            label: "ACCEPT_CONSENT_COOKIE",
          });
        }}
        onDecline={handleDeclineCookie}
        expires={150}
      >
        <p style={{ fontSize: "15px" }}>
          {t(
            "We use the information about you (collected thanks cookies and similar technologies) in order to optimize your experience on our site and analyze how you use it"
          )}
          <a href="/data" target="_blank">{t("Find out more")}</a>
        </p>
        <p style={{ fontSize: "15px" }}>
          {t(
            "The site www.aton-invest.com is an information site whose objective is to promote the activities of Aton Invest SAS and its plan to create investment funds"
          )}
          <a href="/legal" target="_blank">{t("Find out more")}</a>
        </p>
      </CookieConsent>
      <footer className="gradient" style={{ "--pos": "30vw" }}>
        <div className="logo-container">
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <h4 className="title">{t("Quick links")}</h4>
                  <ul className="links">
                    <li>
                      <Link to="/">{t("Home")}</Link>
                    </li>
                    <li>
                      <Link to="/projects">{t("Aton projects")}</Link>
                    </li>
                    <li>
                      <Link to="/investors">{t("Investors")}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("Contact")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-4">
                  <h4 className="title">{t("Useful links")}</h4>
                  <ul className="links">
                    <li>
                      <a href="/legal" target="_blank">
                        {t("Legal Information")}
                      </a>
                    </li>
                    <li>
                      <a href="/data" target="_blank">
                        {t("Personal data processing policy")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-4">
                  <h4 className="title">{t("Newsletter")}</h4>
                  <p className="sp-t-1 text-center">
                    {t("Receive news and developments on Aton Invest projects")}
                  </p>
                  <form onSubmit={handleSubmit} method="post">
                    <div className="input-group sp-t-2">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder={t("Your email address")}
                        required
                        value={newsletterForm.email}
                        onChange={(event) =>
                          setNewsletterForm({
                            ...newsletterForm,
                            email: event.target.value,
                          })
                        }
                      />
                      <button className="btn" type="submit">
                        <span className="material-icons-sharp">send</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 socials">
              <div className="row mt-5 mt-lg-0">
                <div className="col-3 col-lg-12">
                  <a
                    href="https://www.facebook.com/AtonInvest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebook}
                      width="40"
                      height="40"
                      alt="social-facebook"
                    />
                  </a>
                </div>
                <div className="col-3 col-lg-12">
                  <a
                    href="https://www.linkedin.com/company/aton-invest/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedin}
                      width="40"
                      height="40"
                      alt="social-linkedin"
                    />
                  </a>
                </div>
                <div className="col-3 col-lg-12">
                  <a
                    href="https://www.youtube.com/channel/UCAXlxeVTcb4wLay6JafWqXw/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={youtube}
                      width="40"
                      height="40"
                      alt="social-youtube"
                    />
                  </a>
                </div>
                <div className="col-3 col-lg-12">
                  <a
                    href="https://www.instagram.com/aton.invest/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagram}
                      width="40"
                      height="40"
                      alt="social-instagram"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 space-y">
              <p className="people text-uppercase text-center">
                {t("Website language")}
                {i18n.language === "fr" && (
                  <button
                    type="button"
                    className="d-inline-block me-2 flag-btn"
                  >
                    <img
                      src={frenchFlag}
                      width="30"
                      height="30"
                      alt="flag-french"
                    />
                  </button>
                )}
                {i18n.language === "en" && (
                  <button
                    type="button"
                    className="d-inline-block me-2 flag-btn"
                  >
                    <img
                      src={englishFlag}
                      width="30"
                      height="30"
                      alt="flag-english"
                    />
                  </button>
                )}
              </p>
              <hr className="space-y" />
              <p className="people text-uppercase text-center opacity-75">
                Copyright © Aton-Invest 2021. {t("All rights reserved")}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
