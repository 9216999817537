import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar as BSNavbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logos/logo.png";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  async function changeLanguage(e) {
    if (i18n.language === "en") {
      await i18n.changeLanguage("fr");
    } else if (i18n.language === "fr") {
      await i18n.changeLanguage("en");
    }
  }

  return (
    <BSNavbar bg="light" expand="lg">
      <Container>
        <BSNavbar.Brand className="gradient">
          <div className="mask-bottom-right" />
          <div id="brand-container">
            <div className="mask-container-right" />
            <div id="brand-box">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </BSNavbar.Brand>
        <BSNavbar.Toggle />
        <BSNavbar.Collapse className="justify-content-end">
          <Nav
            className="ms-auto mb-lg-0"
            activeKey={location.pathname}
            as="ul"
          >
            <Nav.Item as="li">
              <Nav.Link href="/home" to="/home" as={Link}>
                {t("Home")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link href="/projects" to="/projects" as={Link}>
                {t("Aton Projects")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link href="/investors" to="/investors" as={Link}>
                {t("Investors")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link href="/contact" to="/contact" as={Link}>
                {t("Contact")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              {i18n.language === "en" && (
                <button
                  className="col-auto col-lg-auto d-flex justify-content-center flag"
                  onClick={changeLanguage}
                >
                  <div className="flag-french" />
                </button>
              )}
              {i18n.language === "fr" && (
                <button
                  className="col-auto col-lg-auto d-flex justify-content-center flag"
                  onClick={changeLanguage}
                >
                  <div className="flag-english" />
                </button>
              )}
            </Nav.Item>
          </Nav>
        </BSNavbar.Collapse>
      </Container>
    </BSNavbar>
  );
}
