import React from "react";
import { useTranslation } from "react-i18next";
import graphic from "../../assets/images/graphs/graph-3.png";

export default function Investment() {
  const { t,i18n } = useTranslation();
  
  return (
    <section className="space-y-big">
      <div className="spaced row">
        <h2 className="text-center col-lg-12">{
          i18n.language=='en'?<span>Fundraising</span>:
          <span>Collecte de fonds</span>
        }</h2>
        <p className="sp-t-3 col-lg-12 text-left" style={{fontSize:'26px'}}>
          {i18n.language=='en'?
            <span>You might be a professional investor or an experienced investor, maybe a newbie even just a curious, Aton Invest is raising money from investors to make this project a reality</span>
          : <span>Que vous soyez un investisseur professionnel, un investisseur expérimenté, un débutant ou un simple curieux, Aton Invest collecte des fonds auprès d'investisseurs pour faire de ce projet une réalité.</span>}
        </p>
        <h4 className="text-left col-lg-12" style={{fontWeight:'700'}} >{i18n.language=='en'?<span>Fill the form below to get in touch !</span>:
        <span>Remplissez le formulaire ci-dessous pour nous contacter !</span>
        }</h4>
        
      </div>
    </section>
  );
}
