import React from "react";
import { useTranslation } from "react-i18next";
import image from "../../assets/images/illustrations/pexels-anna-nekrashevich-6801636.jpg";

export default function Better() {
  const { t } = useTranslation();

  return (
    <section className="container" id="better">
      <div className="row space-y-big align-items-center">
        <div className="col-12 col-lg-7 order-0 order-lg-1">
          <div className="spaced space-x-big">
            <h3>{t("We do it better")}</h3>
            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <div className="d-flex check-card">
                  <span className="material-icons-sharp">check_circle</span>
                  <p className="sp-l-1">{t("Toilored Flexible Products")}</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex check-card">
                  <span className="material-icons-sharp">check_circle</span>
                  <p className="sp-l-1">{t("Lower Risk")}</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex check-card">
                  <span className="material-icons-sharp">check_circle</span>
                  <p className="sp-l-1">{t("Better Gain")}</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex check-card">
                  <span className="material-icons-sharp">check_circle</span>
                  <p className="sp-l-1">{t("Compliance")}</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex check-card">
                  <span className="material-icons-sharp">check_circle</span>
                  <p className="sp-l-1">{t("Commitment")}</p>
                </div>
              </div>
            </div>
            <p className="sp-t-1 highlight text-">
              {t(
                "Beyond the financial service, What Aton Invest offers is a mindset, a community and an aspiration to contribute to the improvement of the environment and the living conditions of as many people as possible"
              )}
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div
            className="img-box sp-t-3 order-1 order-lg-0"
            id="img-money"
            style={{ background: "url(" + image + ")" }}
          />
        </div>
      </div>
    </section>
  );
}
