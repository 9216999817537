import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL, SwalAlert } from "../../index";
import { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga";

const initialState = {
  day: "DEFAULT",
  betweenHour: "DEFAULT",
  andHour: "DEFAULT",
  lastName: "",
  firstName: "",
  email: "",
  phoneNumber: "",
};

export default function CustomerService() {
  const { t, i18n } = useTranslation();
  const [customerForm, setCustomerForm] = useState(initialState);

  function mapHours() {
    const hoursMap = [];
    for (let i = 900; i <= 1600; i += i.toString().includes(45) ? 55 : 15) {
      const hour = i.toString().length === 4 ? i : "0" + i;
      hoursMap.push(
        hour.toString().substr(0, 2) + ":" + hour.toString().substr(2)
      );
    }
    return hoursMap;
  }

  function handleSelectClick(e) {
    const minutesToAdd = 30;
    const selectedHours = e.target.value.substr(0, 2);
    const selectedMinutes = e.target.value.substr(3, 6);

    const actualDate = new Date();
    actualDate.setHours(selectedHours);
    actualDate.setMinutes(selectedMinutes);
    actualDate.setSeconds(0);

    const newDate = new Date(
      actualDate.getTime() + minutesToAdd * 60000
    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    setCustomerForm({
      ...customerForm,
      betweenHour: e.target.value,
      andHour: newDate,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    await fetch(`${API_URL}/customer.php`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(customerForm),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Language": i18n.language,
      },
    })
      .then((response) => {
        if (response.ok) {
          SwalAlert.fire({
            title: t("Great"),
            html: t(
              "An customers success manager will get back to you as soon as possible"
            ),
            icon: "success",
          });
          if (getCookieConsentValue("consentCookie")) {
            ReactGA.event({
              category: "EMAIL",
              action: "SEND_EMAIL",
              label: "CUSTOMER_SERVICE_EMAIL",
            });
          }
          e.target.reset();
          setCustomerForm(initialState);
        } else {
          SwalAlert.fire({
            title: t("Oops"),
            html: t("Your appointment could not be transmitted"),
            icon: "error",
          });
        }
      })
      .catch((error) => {
        SwalAlert.fire({
          title: t("Oops"),
          html: t("Your appointment could not be transmitted"),
          icon: "error",
        });
      });
  }

  return (
    <section id="investors-customer">
      <form onSubmit={handleSubmit} method="post" id="form-contact">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("Book an appointment")}</h2>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="day">{t("Day")}</label>
              <select
                name="day"
                id="day"
                className="form-select"
                required
                value={customerForm.day}
                onChange={(event) =>
                  setCustomerForm({
                    ...customerForm,
                    day: event.target.value,
                  })
                }
              >
                <option value="DEFAULT" disabled />
                <option value="monday">{t("Monday")}</option>
                <option value="tuesday">{t("Tuesday")}</option>
                <option value="wednesday">{t("Wednesday")}</option>
                <option value="thursday">{t("Thursday")}</option>
                <option value="friday">{t("Friday")}</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="between">{t("Between")}</label>
              <select
                name="between"
                id="between"
                className="form-select"
                required
                value={customerForm.betweenHour}
                onChange={handleSelectClick}
              >
                <option value="DEFAULT" disabled />
                {mapHours().map((element, i) => (
                  <option key={i} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="and">{t("And")}</label>
              <select
                name="and"
                id="and"
                className="form-select"
                required
                value={customerForm.andHour}
                onChange={(event) =>
                  setCustomerForm({
                    ...customerForm,
                    andHour: event.target.value,
                  })
                }
              >
                <option value="DEFAULT" disabled />
                {mapHours().map((element, i) => (
                  <option key={i} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="first_name">{t("First Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="first_name"
                    name="first_name"
                    required
                    value={customerForm.firstName}
                    onChange={(event) =>
                      setCustomerForm({
                        ...customerForm,
                        firstName: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="last_name">{t("Last Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="last_name"
                    required
                    name="last_name"
                    value={customerForm.lastName}
                    onChange={(event) =>
                      setCustomerForm({
                        ...customerForm,
                        lastName: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="email_customer">{t("Email")}</label>
                  <input
                    className="form-control"
                    type="email"
                    id="email_customer"
                    name="email_customer"
                    required
                    value={customerForm.email}
                    onChange={(event) =>
                      setCustomerForm({
                        ...customerForm,
                        email: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="phone_customer">{t("Phone Number")}</label>
                  <input
                    className="form-control"
                    type="tel"
                    id="phone_customer"
                    name="phone_customer"
                    value={customerForm.phoneNumber}
                    onChange={(event) =>
                      setCustomerForm({
                        ...customerForm,
                        phoneNumber: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button className="btn w-100" type="submit">
                    {t("Reserve")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}
