import React from "react";
import Cheaper from "../components/Projects/Cheaper";
import WhatAre from "../components/Projects/WhatAre";
import Better from "../components/Projects/Better";
import Faster from "../components/Projects/Faster";
import Roadmap from "../components/Projects/Roadmap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Projects() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{t("Aton Invest - Projects")}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <WhatAre />
      <Better />
      <Cheaper />
      <Faster />
      <Roadmap />
    </>
  );
}
