import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";

export default function OurValues() {
  const { t } = useTranslation();

  return (
    <section
      className="gradient"
      id="ourvalues"
      style={{ "--pos-1": "60vw", "--pos-2": "30vw" }}
    >
      <div className="h2-container h2-right" style={{ "--pos": "60vw" }}>
        <h2 className="text-light">{t("Our Values")}</h2>
      </div>
      <Container>
        <Row>
          <div className="col-12 col-lg-3">
            <div className="glass text-center value-card my-2 my-lg-0">
              <div className="spaced">
                <span className="material-icons-sharp">thumb_up</span>
                <h3 className="sp-t-1">{t("Commitment")}</h3>
                <p className="text-center sp-t-2 text-uppercase">
                  {t(
                    "We make it a point of honour to fulfil our mission and our objectives"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="glass text-center value-card my-2 my-lg-0">
              <div className="spaced">
                <span className="material-icons-sharp">lock</span>
                <h3 className="sp-t-1">{t("Security")}</h3>
                <p className="text-center sp-t-2 text-uppercase">
                  {t(
                    "Thanks to the implementation of strict money management and risk management, we make the protection of our client's investment and data our priority"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="glass text-center value-card my-2 my-lg-0">
              <div className="spaced">
                <span className="material-icons-sharp">visibility</span>
                <h3 className="sp-t-1">{t("Transparency")}</h3>
                <p className="text-center sp-t-2 text-uppercase">
                  {t(
                    "No hidden costs, quarterly report on our activities, compliance with regulations and standards"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="glass text-center value-card my-2 my-lg-0">
              <div className="spaced">
                <span className="material-icons-sharp">bar_chart</span>
                <h3 className="sp-t-1">{t("Dynamism")}</h3>
                <p className="text-center sp-t-2 text-uppercase">
                  {t("Dynamic funds and dynamic team")}
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}
