import React from "react";
import { useTranslation } from "react-i18next";

export default function ProjectsItem({
  logo,
  color,
  name,
  description,
  profit,
}) {
  const { t } = useTranslation();

  const logoImage = require(`../../assets/images/logos/${logo}.png`);

  return (
    <div className="project-card col-12 col-md-6 col-lg-12">
      <img src={logoImage} width="171" height="150" alt={name} />
      <h3 className="title sp-t-2" style={{ color: "#" + color }}>
        {t(name)}
      </h3>
    </div>
  );
}
