import React from "react";
import { useTranslation } from "react-i18next";

export default function RoadmapItem({
  title,
  date,
  objectiveTitle,
  objectiveNumber,
  children,
}) {
  const { t } = useTranslation();

  return (
    <div className="col-10 roadmap-card">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="spaced">
              <div className="title">
                <h4 className="text-uppercase">{t(title)}</h4>
                <div className="date">{t(date)}</div>
                <h3 className="objectif">
                  <small>{t(objectiveTitle)}</small>
                  {t(objectiveNumber)}
                </h3>
              </div>
              <div className="content spaced">
                <ul>{children}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
