import React from "react";
import { useTranslation } from "react-i18next";
import ProjectsItem from "./ProjectsItem";
import { Container, Row } from "react-bootstrap";

export default function WhatAre() {
  const { t } = useTranslation();

  return (
    <Container as="section" id="whatare">
      <Row className="space-y-big">
        <div className="col-12 text-center">
          <h2>{t("What are Aton Projects")}</h2>
        </div>
        <div className="col-12">
          <div className="spaced">
            <h3>{t("Aton Projects")}</h3>
            <p className="sp-t-1">
              {t(
                "Based on our personal experience and the global vision we have acquired through setting up this project, it became obvious to us to offer one asset management company that will provide 6 diversified investment funds. Our goal with this tailor-made offer is to be able to meet demand both in terms of short-term investment and in terms of long-term and innovative investment"
              )}
            </p>
          </div>
        </div>
        <Row>
          <div className="col-12 col-lg-4">
            <div className="gradient-border">
              <Row>
                <ProjectsItem
                  logo="digital"
                  color="A700C4"
                  name="Digital Asset"
                  description="Long term investment in digital asset and other blockchain projects"
                  profit="CAGR target: +10% / Year"
                />
                <ProjectsItem
                  logo="green"
                  color="0DAA00"
                  name="Green"
                  description="Investment in new technologies and eco-friendly companies"
                  profit="CAGR target: +10% / Year"
                />
              </Row>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="gradient-border">
              <Row>
                <ProjectsItem
                  logo="value"
                  color="2E7AA6"
                  name="Value"
                  description="Long term investment based on fundamental analysis of companies and their dividend policy"
                  profit="CAGR target: +10% / Year"
                />
                <ProjectsItem
                  logo="rente"
                  color="517b88"
                  name="Rente"
                  description="Long term investment based on fundamental analysis of companies and their dividend policy"
                  profit="CAGR target: +10% / Year"
                />
              </Row>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div>
              <Row>
                <ProjectsItem
                  logo="perf"
                  color="FF0000"
                  name="Performance"
                  description="Short term investment on financial markets (forex, futures, CFD…)"
                  profit="Profit target: +50% / Year"
                />
                <ProjectsItem
                  logo="croissance"
                  color="b55e00"
                  name="Croissance"
                  description="Short/medium term investment on financial markets (forex, futures, stocks, …)"
                  profit="Profit target: +30% / Year"
                />
              </Row>
            </div>
          </div>
        </Row>
      </Row>
    </Container>
  );
}
