import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Legal() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{t("Aton Invest - Legal Information")}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center space-y-big">
          <div className="col-12 col-lg-8">
            <div className="spaced">
              <h1>Mentions légales</h1>
              <h2>Objectif du site</h2>
              <p>
                Le site www.aton-invest.com est un site d'information dont
                l'objectif est de promouvoir les activités d’Aton Invest SAS et
                son projet de création de fonds d’investissement. Aucun produit
                fini n’est en vente, uniquement une proposition de financement
                d’un projet de création de fonds d’investissement.
              </p>
              <h2>Nature de l'information disponible sur le site</h2>
              <p>Ce site n’est pas un site transactionnel.</p>
              <p>
                Toute information peut être modifiée sans préavis par Aton
                Invest SAS. Les informations publiées sur le site ne sont ni une
                incitation ni un conseil en vue d’un quelconque investissement
                ou arbitrage de valeurs mobilières ou tout autre produit de
                gestion ou d’investissement.
              </p>
              <p>
                Aton Invest SAS, ses employés, ses contributeurs et ses
                fournisseurs de données déclinent toute responsabilité dans
                l’utilisation qui pourrait être faite de cette information et
                des conséquences qui pourraient en découler, notamment au niveau
                des décisions qui pourraient être prises ou des actions qui
                pourraient être entreprises à partir de cette information.
              </p>
              <h2>Accès au site</h2>
              <p>
                L'accès aux produits et services présentés sur le site peut
                faire l'objet de restrictions à l'égard de certaines personnes
                ou de certains pays qui sont soumis à une législation
                spécifique.
              </p>
              <p>
                En particulier, il est précisé que les projets d’Aton Invest
                (Futurs OPC) n’ont pas été ni ne seront enregistrés auprès
                d’aucun organisme ou autorité des marchés financiers, au sens de
                la législation sur les sociétés d'investissement et les
                amendements ou tout autre règlement relatif aux valeurs
                mobilières.
              </p>
              <p>
                Aton Invest SAS conseille à toute personne intéressée de
                s'assurer préalablement qu'elle est juridiquement autorisée à
                souscrire au financement du projet Aton Invest SAS présentés sur
                le site.
              </p>
              <p>
                La proposition de participation au financement du projet
                présentée ici ne sera fournie à une personne si la loi de son
                pays d'origine, ou de tout autre pays qui la concernerait,
                l'interdit. Le lecteur du présent message est prié de s'assurer
                qu´il est juridiquement autorisé à participer au projet proposé
                dans le présent site dans le pays à partir duquel la connexion
                est établie.
              </p>
              <h2>Liens hypertexte</h2>
              <p>
                L'établissement de liens hypertextes par toute personne en
                direction du site, à l'insu de Aton Invest SAS, n'est pas
                autorisé.
              </p>
              <p>
                Avant toute mise en place d'un ou de tels liens, il vous
                appartient d'en faire la demande auprès de Aton Invest SAS :{" "}
                <a href="mailto:contact@aton-invest.com">
                  contact@aton-invest.com
                </a>
              </p>
              <h2>Droit d'auteur et propriété intellectuelle</h2>
              <p>
                Le site ainsi que tous les éléments le composant et notamment :
                les textes, les images fixes ou animées, les compositions
                sonores, la charte graphique, les bases de données, etc… sont la
                propriété exclusive de Aton Invest SAS et sont protégés au titre
                des dispositions du code de la propriété intellectuelle.
              </p>
              <p>
                Par exception, certains contenus (données, images) sont la
                propriété de leurs auteurs respectifs.
              </p>
              <p>
                En conséquence, toute reproduction ou représentation totale ou
                partielle de ce site ou d’un de ses éléments sans autorisation
                expresse et préalable de Aton Invest SAS est interdite et
                constitue une contrefaçon punissable selon les dispositions du
                Code de la propriété intellectuelle et du Code pénal.
              </p>
              <p>
                Les marques, noms commerciaux et logos figurant sur ce site sont
                déposés. Leur reproduction totale ou partielle, effectuée à
                partir des éléments du site, est prohibée.
              </p>
              <h2>Langue et droit applicable</h2>
              <p>Le site a pour langue officielle la langue française.</p>
              <p>
                Ce site Web est régi par le droit français. Toute action
                relative à ce site Web devra être exercée devant les tribunaux
                français.
              </p>
              <p>Aton Invest SAS</p>
              <p>14, Avenue de l’Opéra - 75001 Paris, France</p>
              <p>
                Tel. : <a href="tel:0142402807">(+33) 01 42 40 28 07</a>
              </p>
              <p>Private Investment management company</p>
              <p>
                Public limited company with share capital of € 12 350 - RCS
                Paris 901130765
              </p>
              <p>
                Président : Njaay Holding Ltd représentée par Ibrahima OUSMANE
              </p>
              <p>Directeur Général : Ibrahima OUSMANE</p>
              <br />
              <hr />
              <br />
              <h3>Informations légales</h3>
              <p>
                Les informations présentées ci-dessus ne constituent ni une
                offre de souscription, ni un conseil en investissement. Ces
                informations peuvent être partielles et sont susceptibles d’être
                modifiées sans préavis. Les objectifs de performances sont des
                projections obtenues à la suite de simulations menées en
                interne. Les performances passées ne préjugent pas des
                performances futures. La proposition de financement peut faire
                l’objet de restrictions à l’égard de certaines personnes ou de
                certains pays. Les spécificités et les conditions de financement
                sont décrites dans le contrat de financement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
