import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";

export default function Cheaper() {
  const { t, i18n } = useTranslation();

  const graphicOne = require(`../../assets/images/graphs/graph-1-${i18n.language}.png`);
  const graphicTwo = require(`../../assets/images/graphs/graph-2-${i18n.language}.png`);

  return (
    <Container as="section" id="cheaper">
      <Row className="space-y-big align-items-center">
        <div className="col-12">
          <div className="spaced">
            <h3>{t("We do it cheaper")}</h3>
            <p className="sp-t-1">
              {t(
                "Competitors leave only 46% of the profits to their customer while Aton Invest the customer get at leat 70% of the profit"
              )}
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="img-gradient-box col-12 col-lg-5">
              <div
                className="w-100 graphic-img"
                style={{ backgroundImage: "url(" + graphicOne + ")" }}
              />
              <div className="p-2 pt-4 fw-bold text-center">
                <h3>{t("Competitors fees breakdown")}</h3>
                <p>{t("Maximum 46% of profits for the customer")}</p>
              </div>
            </div>
            <div className="img-gradient-box col-12 col-lg-5">
              <div
                className="w-100 graphic-img"
                style={{ backgroundImage: "url(" + graphicTwo + ")" }}
              />
              <div className="p-2 pt-4 fw-bold text-center">
                <h3>{t("Aton Invest fees breakdown")}</h3>
                <p>{t("Minimum 70% of the profits for the customer")}</p>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}
