import React from "react";
import { useTranslation } from "react-i18next";
import earth from "../../assets/images/illustrations/earth-4480280_640.jpg";
import { Container, Row } from "react-bootstrap";

export default function Environment() {
  const { t } = useTranslation();

  return (
    <section id="environement" className="position-relative">
      <div className="h2-container h2-left" style={{ "--pos": "30vw" }}>
        <h2>{t("Our social & environmental commitment")}</h2>
      </div>
      <Container>
        <Row className="space-y-big align-items-center">
          <div className="col-12 col-lg-7">
            <div className="spaced">
              <p>
                {t(
                  "Aton invest also wishesh to have a positive social and environmental impact, therefore we will implement a set of actions to raise awareness of “financial literacy” , to help underprivileged populations, and to finance projects to fight against global warming consequences"
                )}
              </p>
            </div>
            <div className="spaced-big">
              <div className="gradient" id="non-profit">
                <h1>
                  <span className="fat">{t("2%")}</span>
                  <small>
                    {t("Of Aton Invest profit to finance Aton Non-Profit")}
                  </small>
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div
              className="img-box sp-t-3"
              id="img-environement"
              style={{ background: "url(" + earth + ")" }}
            />
            <div className="spaced">
              <h4 className="text-uppercase sp-t-3">{t("Aton Non-Profit")}</h4>
              <p className="sp-t-1">
                {t(
                  "A non profit organization to promote, empower and finance our commitment to have a positive environmental and social positive impact"
                )}
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}
