import React from "react";
import { useTranslation } from "react-i18next";
import hands from "../../assets/images/illustrations/three-team-hands.jpg";
import { Container, Row } from "react-bootstrap";

export default function OurMission() {
  const { t } = useTranslation();

  return (
    <section id="ourmission">
      <div className="divider reverse" style={{ "--pos": "40vw" }}>
        <div className="h2-container h2-left">
          <h2>{t("Our mission")}</h2>
        </div>
      </div>
      <br />
      <Container>
        <Row className="space-y-big align-items-center">
          <div className="col-12 col-lg-7">
            <div className="spaced space-x-big">
              <h3>
                {t(
                  "Leadings all our clients, with no distinction to a secured and profitable investment"
                )}
              </h3>
              <p className="sp-t-1">
                {t(
                  "Aton invest meets the need of individuals to invest with confidence and transparency in order to build their wealth. Aton invest will apply strict money management,strict risk management in order to provide the lowest financial and non-financial riskpossible, plus more flexibility on the investment and withdrawal terms, this is the qualitywe commit to our customers"
                )}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div
              className="img-box sp-t-3"
              id="img-money"
              style={{ background: "url(" + hands + ")" }}
            />
          </div>
        </Row>
      </Container>
    </section>
  );
}
