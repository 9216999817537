import React from "react";
import { useTranslation } from "react-i18next";
import RoadmapItem from "./RoadmapItem";

export default function Roadmap() {
  const { t } = useTranslation();

  return (
    <section id="roadmap">
      <div className="divider" style={{ "--pos": "75vw" }}>
        <div className="h2-container h2-right">
          <h2>{t("Roadmap")}</h2>
        </div>
      </div>
      <div className="container space-y-big">
        <div className="row justify-content-center" id="roadmap-container">
          <div className="col-12">
            <div className="row justify-content-center">
              <RoadmapItem
                title="Creation"
                date="2022-2024"
                objectiveTitle="Fund Raising Goal"
                objectiveNumber="3M€"
              >
                <li className="selectable">
                  <p>{t("Focus on branding & marketing strategy")}</p>
                </li>
                <li className="selectable">
                  <p>{t("R&D and trading bots back-testing")}</p>
                </li>
                <li className="selectable">
                  <p>{t("Recruiting missing skills")}</p>
                </li>
              </RoadmapItem>

              <RoadmapItem
                title="1st funds launch"
                date="2025-2026"
                objectiveTitle="Asset Under Management"
                objectiveNumber="75M€"
              >
                <li className="selectable">
                  <p>
                    {t(
                      "Launching of the funds Croissance, Digital Asset and Value"
                    )}
                  </p>
                </li>
                <li className="selectable">
                  <p>{t("Focus on creating an Aton Community")}</p>
                </li>
                <li className="selectable">
                  <p>
                    {t(
                      "Creation of the Aton Non-Profit (non-profit organization)"
                    )}
                  </p>
                </li>
              </RoadmapItem>

              <RoadmapItem
                title="UE Expansion"
                date="2027-2030"
                objectiveTitle="Asset Under Management"
                objectiveNumber="500M€"
              >
                <li className="selectable">
                  <p>
                    {t(
                      "Launching of remaining Aton funds Performance, Green and Rente"
                    )}
                  </p>
                </li>
                <li className="selectable">
                  <p>{t("Implementation in Europe’s major cities")}</p>
                </li>
                <li className="selectable">
                  <p>{t("Recruiting missing skills")}</p>
                </li>
              </RoadmapItem>

              <RoadmapItem
                title="International Expansion"
                date="2031 & more"
                objectiveTitle="Asset Under Management"
                objectiveNumber="1000M€"
              >
                <li className="selectable">
                  <p>{t("Focus on the Brand reputation")}</p>
                </li>
                <li className="selectable">
                  <p>{t("Spread of Aton’s Values")}</p>
                </li>
                <li className="selectable">
                  <p>{t("Implementation in World’s major cities")}</p>
                </li>
              </RoadmapItem>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
