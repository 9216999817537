import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../views/Home";
import Projects from "../views/Projects";
import Investors from "../views/Investors";
import Contact from "../views/Contact";
import Legal from "../views/Legal";
import Data from "../views/Data";

export default function Router() {
  return (
    <Switch>
      <Route path={["/", "/home"]} exact>
        <Home />
      </Route>
      <Route path="/projects" exact>
        <Projects />
      </Route>
      <Route path="/investors" exact>
        <Investors />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
      <Route path="/legal" exact>
        <Legal />
      </Route>
      <Route path="/data" exact>
        <Data />
      </Route>
    </Switch>
  );
}
