import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import facebookImg from "../../assets/images/socials/facebook2.svg";
import linkedinImg from "../../assets/images/socials/linkedin.svg";
import { API_URL, SwalAlert } from "../../index";
import { Row } from "react-bootstrap";
import { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga";

const initialState = {
  gender: "male",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  subject: "",
  message: "",
};

export default function ContactForm() {
  const { t, i18n } = useTranslation();
  const [contactForm, setContactForm] = useState(initialState);

  async function handleSubmit(e) {
    e.preventDefault();

    await fetch(`${API_URL}/contact.php`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(contactForm),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Content-Language": i18n.language,
      },
    })
      .then((response) => {
        if (response.ok) {
          SwalAlert.fire({
            title: t("Great"),
            html: t(
              "Our customer service will get back you as soon as possible"
            ),
            icon: "success",
          });
          if (getCookieConsentValue("consentCookie")) {
            ReactGA.event({
              category: "EMAIL",
              action: "SEND_EMAIL",
              label: "CONTACT_EMAIL",
            });
          }
          e.target.reset();
          setContactForm(initialState);
        } else {
          SwalAlert.fire({
            title: t("Oops"),
            html: t("Your contact form could not be sent"),
            icon: "error",
          });
        }
      })
      .catch((error) => {
        SwalAlert.fire({
          title: t("Oops"),
          html: t("Your contact form could not be sent"),
          icon: "error",
        });
      });
  }

  return (
    <div className="container">
      <Row className="space-y-big align-items-center">
        <section className="col-12 col-lg-7 order-2 order-lg-1">
          <form onSubmit={handleSubmit} method="post" id="form-contact">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="gender">{t("Gender")}</label>
                  <select
                    className="form-select"
                    id="gender"
                    name="gender"
                    required
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        gender: event.target.value,
                      })
                    }
                  >
                    <option value="male">{t("Man")}</option>
                    <option value="female">{t("Woman")}</option>
                    <option value="other">{t("Other")}</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="first_name">{t("First Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="first_name"
                    name="first_name"
                    required
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        firstName: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="last_name">{t("Last Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        lastName: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="email">{t("Email")}</label>
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        email: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label htmlFor="phone">{t("Phone Number")}</label>
                  <input
                    className="form-control"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        phoneNumber: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="subject">{t("Subject")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="subject"
                    name="subject"
                    required
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        subject: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="message">{t("Message")}</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    required
                    value={contactForm.day}
                    onChange={(event) =>
                      setContactForm({
                        ...contactForm,
                        message: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button className="btn w-100" type="submit">
                    {t("Send")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
        <section className="col-12 col-lg-5 order-1 order-lg-2">
          <div className="spaced">
            <h3>{t("Get in touch")}</h3>
            <h2>{t("Contact us to get more information")}</h2>
            <p>
              {t(
                "Let get in touch! we are reachable by mail or email, also via social media. Leave us a message and our customer service will answer you as soon as possible"
              )}
            </p>
            <p>
              <span className="selectable">{t("Headquarters")}</span>
              <br />
              <span className="selectable">Aton Invest SAS</span>
              <br />
              <span className="selectable">14 Avenue de I'Opera</span>
              <br />
              <span className="selectable">75001 Paris - France</span>
            </p>
            <p>
              Email :&nbsp;
              <a className="selectable" href="mailto:contact@aton-invest.com">
                contact@aton-invest.com
              </a>
            </p>
            <div className="row">
              <div className="col-12">
                <a
                  href="https://www.facebook.com/AtonInvest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="img-contact"
                    src={facebookImg}
                    width="55"
                    height="55"
                    alt="social-facebook"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/aton-invest/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="img-contact"
                    src={linkedinImg}
                    width="55"
                    height="55"
                    alt="social-linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </Row>
    </div>
  );
}
