import { useEffect } from "react";
import ReactGA from "react-ga";
import { GA_CODE } from "../index";
import { getCookieConsentValue } from "react-cookie-consent";

export default function useGaTracker() {
  const consent = getCookieConsentValue("consentCookie");

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      if (consent) {
        ReactGA.initialize(GA_CODE);
      }
    }
  }, [consent]);
}
