import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./views/App";
import Analytics from "react-router-ga";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TagManager from "react-gtm-module";
import { getCookieConsentValue } from "react-cookie-consent";
import "./assets/styles/main.css";
import "./plugins";

export const API_URL = "https://aton-invest.com/api/mails";

// UA-102030089-4 (Debug Code)
// UA-211616619-1 (Production code)
export const GA_CODE = "UA-211616619-1";
// GTM-M542NGX (Debug code)
// GTM-TJMCKRC (Production code)
export const GMT_CODE = "GTM-TJMCKRC";

export const tagManagerArgs = {
  gtmId: GMT_CODE,
};

TagManager.initialize(tagManagerArgs);

export const SwalAlert = withReactContent(Swal);

const conscentCookie = getCookieConsentValue("consentCookie");

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        {conscentCookie ? (
          <Analytics id={GA_CODE}>
            <App />
          </Analytics>
        ) : (
          <App />
        )}
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
