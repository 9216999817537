import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Simulator from "../components/Investors/Simulator";
import Investment from "../components/Investors/Investment";
import CustomerService from "../components/Investors/CustomerService";
import Invest from "../components/Investors/Invest";
import { Container, Row } from "react-bootstrap";

export default function Investors(props) {
  const { t,i18n } = useTranslation();
  const location = useLocation();
 return (

    <>
      
      <Container>
        <Row>
          <div className="col-lg-12">
            <Investment />
            <CustomerService />
          </div>
        </Row>
      </Container>
    </>
  );
}
