import React from "react";
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Footer/Footer";
import useGaTracker from "../hooks/useGaTracker";
import ScrollTop from "../components/Footer/ScrollTop";
import Router from "../components/Router";

export default function App() {
  useGaTracker();

  return (
    <>
      <React.Suspense fallback="loading">
        <Navbar />
        <Router />
        <Footer />
      </React.Suspense>
      <ScrollTop />
    </>
  );
}
