import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import coffee from "../../assets/images/illustrations/business-meeting-over-coffee.jpg";

export default function WhatIs() {
  const { t } = useTranslation();

  return (
    <section id="whatis">
      <div className="divider" style={{ "--pos": "50vw" }}>
        <div className="h2-container h2-right">
          <h2>{t("What is Aton Invest")}</h2>
        </div>
      </div>
      <br />
      <Container className="mt-10 mt-md-0">
        <Row className="space-y-big align-items-center">
          <div className="col-12 col-lg-7 order-lg-1">
            <div className="spaced space-x-big">
              <p>
                {t(
                  "Aton Invest is an aspiration, a project to build a new generation of asset management company that will provide an accessible, reliable and robust diversified investment funds tailored to help you to build your wealth by the simpliest way"
                )}
              </p>
              <p>
                {t(
                  "Aton Invest will be the asset manager that ensure the prospection of investment opportunities, the arbitrage of ongoing investment and the administrative management of the investment funds that it will create as well as the commercial development and the distribution of its financial product"
                )}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-5 order-lg-0">
            <div
              className="img-box sp-t-3"
              id="img-money"
              style={{ background: "url(" + coffee + ")" }}
            />
          </div>
        </Row>
      </Container>
    </section>
  );
}
