import React from "react";
import Wealth from "../components/Home/Wealth";
import WhatIs from "../components/Home/WhatIs";
import OurMission from "../components/Home/OurMission";
import OurValues from "../components/Home/OurValues";
import Environment from "../components/Home/Environment";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{t("Aton Invest - Home")}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <Wealth />
      <WhatIs />
      <OurMission />
      <OurValues />
      <Environment />
    </>
  );
}
