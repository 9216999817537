import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import hero from "../../assets/images/illustrations/hero.jpg";

export default function Wealth() {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="space-y-big align-items-center" as="section">
        <div className="col-12 col-lg-7">
          <div className="spaced space-x-big">
            <h1 className="wealth">{t("Build your wealth")}</h1>
            <h3 className="sp-t-1">
              {t("Diversified investment funds tailored to fit your ambition")}
            </h3>
            <p className="sp-t-1">
              {t(
                "We commit to help you building your wealth by the simpliest way. Unique financial services providing secured and profitable investments"
              )}
            </p>
            <div className="sp-t-2">
              <Link to="/investors" className="btn">
                {t("Get started")}
              </Link>
              <Link to="/projects" id="more">
                {t("Learn more")}
                <span className="material-icons-sharp">arrow_forward</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div
            className="img-wealth sp-t-3 d-none d-lg-block"
            id="img-money"
            style={{
              background: "url(" + hero + ")",
            }}
          />
        </div>
      </Row>
    </Container>
  );
}
